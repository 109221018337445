import { get, post,getIpAddressEndpoint} from "./../../services/RestAPIWrapper";
import {
    PORTALLOGO_REQUEST,
    PORTALLOGO_SUCCESS,
    PORTALLOGO_FAIL,
    SIGNER_DETAIL_REQUEST,
    SIGNER_DETAIL_SUCCESS,
    SIGNER_DETAIL_FAIL,
    CREATE_SIGNER_FAIL,
    CREATE_SIGNER_SUCCESS,
    CREATE_SIGNER_REQUEST,
    IPADDRESS_DETAIL_REQUEST,
    IPADDRESS_DETAIL_SUCCESS,
    IPADDRESS_DETAIL_FAIL,
    DOWNLOAD_SIGNEDDOCUMENT_REQUEST,
    DOWNLOAD_SIGNEDDOCUMENT_SUCCESS,
    DOWNLOAD_SIGNEDDOCUMENT_FAIL,
    CALLBACK_REQUEST,
    CALLBACK_SUCCESS,
    CALLBACK_FAIL,
    EMAILAUTHENTICATION_REQUEST,
	EMAILAUTHENTICATION_SUCCESS,
	EMAILAUTHENTICATION_FAIL,
	DOCUMENT_DETAIL_REQUEST,
	DOCUMENT_DETAIL_SUCCESS,
	DOCUMENT_DETAIL_FAIL,
	SIGNDOCUMENT_SUCCESS,
	SIGNDOCUMENT_REQUEST,
	SIGNDOCUMENT_FAIL,
    ISERRORSERVER,
    RESET
} from '../constants/App';

import { endPoints } from '../../config/ApiConfig';



//Action get APP logo from Dynamics 
function portalAppLogoRequest() {
    return {
        type: PORTALLOGO_REQUEST
    }
}

function portalAppLogoReceive(json) {
    return {
        type: PORTALLOGO_SUCCESS,
        data: json
    }
}

export function getPortalLogo(code) {

    return dispatch => {
        dispatch(portalAppLogoRequest());

        return get(endPoints.appLogoApi.endPoint + code,'', json => {
            if (json.Success === 'false') {
                return dispatch({ type: PORTALLOGO_FAIL, data: json });
            } else {
                return (dispatch(portalAppLogoReceive(json)));
            }
        }, err => {
            console.log(err);
            return dispatch(errorServer(err));
        } )
    };
}

// GetdocumentDetails Details
function signerDetailRequest() {
    return {
        type: SIGNER_DETAIL_REQUEST
    }
}

function signerDetailReceiveData(json) {
    return {
        type: SIGNER_DETAIL_SUCCESS,
        data: json
    }
}

export function getSignerDetails(code) {
    return dispatch => {
        dispatch(signerDetailRequest());

        return get(endPoints.getSignerDetails.endPoint + code, "", json => {
            if (json.Success === 'false') {
                return dispatch({ type: SIGNER_DETAIL_FAIL, data: json });
            } else {
                return (dispatch(signerDetailReceiveData(json)));
            }
        }, err => {
            console.log(err);
            return dispatch(errorServer(err));
        });
    };
}

// create Signer
function createSignerRequest() {
    return {
        type: CREATE_SIGNER_REQUEST
    }
}

function createSignerSaveReceiveData(json, id='') {
    return {
        type: CREATE_SIGNER_SUCCESS,
        data: json,
        sign_id: id,
    }
}

export function createSigner(signer) {

    return dispatch => {
        dispatch(createSignerRequest());

        return post(endPoints.createSigningOrder.endPoint, signer, json => {
            if (json.Success === 'false') {
                return dispatch({ type: CREATE_SIGNER_FAIL, data: json });
            } else {
                const query = new URLSearchParams(json);
                const signingOrderId = query.get('sign_id');
                return (dispatch(createSignerSaveReceiveData(json, signingOrderId)));
            }
        }, err => {
            console.log(err);
            return dispatch(errorServer(err));
        })
    };
}

function getIpAddressRequest() {
    return {
        type: IPADDRESS_DETAIL_REQUEST
    }
}
function setIpAddress(json) {
    return {
        type: IPADDRESS_DETAIL_SUCCESS,
        data: json
    }
}

export function getIpAddress() {

    return dispatch => {
        dispatch(getIpAddressRequest());
        return getIpAddressEndpoint(endPoints.getIpAddress.endPoint, "", json => {
            if (json.Success === 'false') {
                return dispatch({ type: IPADDRESS_DETAIL_FAIL, data: json });
            } else {
                return (dispatch(setIpAddress(json)));
            }
        }, err => {
            console.log(err);
            return dispatch(errorServer(err));
        });
    };
}

function callbackRequest() {
    return {
        type: CALLBACK_REQUEST
    }
}

function callbackData(json) {
    return {
        type: CALLBACK_SUCCESS,
        data: json
    }
}

export function callback(authorizationResponse) {
    return dispatch => {
        dispatch(callbackRequest());
        return post(endPoints.callback.endPoint, authorizationResponse, json => {
            if (json.Success === 'false') {
                return dispatch({ type: CALLBACK_FAIL, data: json });
            } else {
                return (dispatch(callbackData(json)));
            }
        }, err => {
            console.log(err);
            return dispatch(errorServer(err));
        });
    };
}


function downloadSignedDocumentRequest() {
    return {
        type: DOWNLOAD_SIGNEDDOCUMENT_REQUEST
    }
}

function downloadSignedDocumentSuccess(json) {
    return {
        type: DOWNLOAD_SIGNEDDOCUMENT_SUCCESS,
        data: json
    }
}

export function downloadSignedDocument(documentParams) {
    return dispatch => {
        dispatch(downloadSignedDocumentRequest());
        return get(endPoints.downloadSignedDocument.endPoint,documentParams,"", json => {
            if (json.Success === 'false') {
                return dispatch({ type: DOWNLOAD_SIGNEDDOCUMENT_FAIL, data: json });
            } else {
                return (dispatch(downloadSignedDocumentSuccess(json)));
            }
        }, err => {
            console.log(err);
            return dispatch(errorServer(err));
        });
    };
}

// Get documents Details
function documentDetailRequest() {
    return {
        type: DOCUMENT_DETAIL_REQUEST
    }
}

function documentDetailReceiveData(json) {
    return {
        type: DOCUMENT_DETAIL_SUCCESS,
        data: json
    }
}

export function getDocuments(code) {
    return dispatch => {
        dispatch(documentDetailRequest());

        return get(endPoints.getDocuments.endPoint + code, "", json => {
            if (json.Success === 'false') {
                return dispatch({ type: DOCUMENT_DETAIL_FAIL, data: json });
            } else {
                return (dispatch(documentDetailReceiveData(json)));
            }
        }, err => {
            console.log(err);
            return dispatch(errorServer(err));
        });
    };
}

//Action get APP logo from Dynamics 
function authenticateSignerRequest() {
    return {
        type: EMAILAUTHENTICATION_REQUEST
    }
}

function authenticateSignerReceive(json) {
    return {
        type: EMAILAUTHENTICATION_SUCCESS,
        data: json
    }
}

export function authenticateSigner(code,emailAdress) {

    return dispatch => {
        dispatch(authenticateSignerRequest());

        return get(endPoints.authenticateSigner.endPoint + code+"/"+emailAdress,'', json => {
            if (json.Success === 'false') {
                return dispatch({ type: EMAILAUTHENTICATION_FAIL, data: json });
            } else {
                return (dispatch(authenticateSignerReceive(json)));
            }
        }, err => {
            console.log(err);
            return dispatch(errorServer(err));
        } )
    };
}

function signDocumentRequest() {
    return {
        type: SIGNDOCUMENT_REQUEST
    }
}

function signDocumentReceive(json) {
    return {
        type: SIGNDOCUMENT_SUCCESS,
        data: json
    }
}

export function signDocument(code) {

    return dispatch => {
        dispatch(signDocumentRequest());

        return post(endPoints.signDocument.endPoint + code,'', json => {
            if (json.Success === 'false') {
                return dispatch({ type: SIGNDOCUMENT_FAIL, data: json });
            } else {
                return (dispatch(signDocumentReceive(json)));
            }
        }, err => {
            console.log(err);
            return dispatch(errorServer(err));
        } )
    };
}

// Reset state 
function resetRequest() {
    return {
        type: RESET
    }
}

export function reset() {

    return dispatch => {
        dispatch(resetRequest());
    };
}


// Error 
export function errorServer(error) {
    return {
        type: ISERRORSERVER,
        data: error
    }
}
