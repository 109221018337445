import axios from 'axios';
import { apiPath, headers } from '../config/ApiConfig';


export function get(endPoint, inputParam, sucessCallback, errorCallback) {
    return axios.get(`${apiPath}${endPoint}${inputParam}`, headers, { timeout: 20000 })
        .then(response => response.data)
        .then(sucessCallback)
        .catch(errorCallback);
}
export function post(endPoint, inputParam, sucessCallback, errorCallback) {
    return axios.post(`${apiPath}${endPoint}`, { ...inputParam }, headers, { timeout: 20000 })
        .then(response => response.data)
        .then(sucessCallback)
        .catch(errorCallback);
}

export function getIpAddressEndpoint(endPoint,inputParam,sucessCallback, errorCallback) {
    return axios.get(`${endPoint}${inputParam}`,{timeout:20000}).then(response => response.data.ip)
        .then(sucessCallback)
        .catch(errorCallback);
}