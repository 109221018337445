import {
	PORTALLOGO_REQUEST,
	PORTALLOGO_SUCCESS,
	PORTALLOGO_FAIL,
	SIGNER_DETAIL_REQUEST,
	SIGNER_DETAIL_SUCCESS,
	SIGNER_DETAIL_FAIL,
	CREATE_SIGNER_REQUEST,
	CREATE_SIGNER_SUCCESS,
	CREATE_SIGNER_FAIL,
	RESET,
	IPADDRESS_DETAIL_REQUEST,
	IPADDRESS_DETAIL_SUCCESS,
	IPADDRESS_DETAIL_FAIL,
	DOWNLOAD_SIGNEDDOCUMENT_REQUEST,
	DOWNLOAD_SIGNEDDOCUMENT_SUCCESS,
	DOWNLOAD_SIGNEDDOCUMENT_FAIL,
	CALLBACK_REQUEST,
	CALLBACK_SUCCESS,
	CALLBACK_FAIL,
	EMAILAUTHENTICATION_REQUEST,
	EMAILAUTHENTICATION_SUCCESS,
	EMAILAUTHENTICATION_FAIL,
	DOCUMENT_DETAIL_REQUEST,
	DOCUMENT_DETAIL_SUCCESS,
	DOCUMENT_DETAIL_FAIL,
	SIGNDOCUMENT_SUCCESS,
	SIGNDOCUMENT_REQUEST,
	SIGNDOCUMENT_FAIL
} from '../constants/App';

const initialState = {
	settings: {},
	signerDetails: {},
	createSignerUrl: {},
	isSignerAuthenticated: {},
	documentDetails: {},
	isDocumentSigned: {},
	responseCallback: {},
	fetching: false,
	error: '',
	callback: "",
	ipAddress: "",
};

export default function signerPage(state = initialState, action = {}) {
	switch (action.type) {
		case PORTALLOGO_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case PORTALLOGO_SUCCESS:
			return {
				...state,
				settings: action.data,
				fetching: false,
				responseCallback: {
					...state.responseCallback,
					LanguageCode: action.data.LanguageCode
				},
				error: ''
			};
		case PORTALLOGO_FAIL:
			return {
				...state,
				settings: action.data,
				fetching: false,
				error: ''
			};
		case SIGNER_DETAIL_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case SIGNER_DETAIL_SUCCESS:
			return {
				...state,
				signerDetails: action,
				fetching: false,
				responseCallback: {
					...state.responseCallback,
					SignerId: action.data.SignerId,
					TaskId: action.data.TaskId,
					OrgId: action.data.OrgId
				},
				error: ''
			};
		case SIGNER_DETAIL_FAIL:
			return {
				...state,
				signerDetails: action,
				fetching: false,
				error: ''
			};
		case CREATE_SIGNER_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case CREATE_SIGNER_SUCCESS:
			return {
				...state,
				createSignerUrl: action.data,
				fetching: false,
				responseCallback: {
					...state.responseCallback,
					sign_id: action.sign_id
				},
				error: ''
			};
		case CREATE_SIGNER_FAIL:
			return {
				...state,
				createSignerUrl: action.data,
				fetching: false,
				error: ''
			};
		case IPADDRESS_DETAIL_REQUEST:
			return {
				...state,
				error: ''
			};
		case IPADDRESS_DETAIL_SUCCESS:
			return {
				...state,
				ipAddress: action.data,
				error: ''
			};
		case IPADDRESS_DETAIL_FAIL:
			return {
				...state,
				ipAddress: action.data,
				error: ''
			};
		case CALLBACK_REQUEST:
			return {
				...state,
				callback: action,
				fetching: true,
				error: ''
			};
		case CALLBACK_SUCCESS:
			return {
				...state,
				callback: action,
				fetching: false,
				error: ''
			};
		case CALLBACK_FAIL:
			return {
				...state,
				callback: action,
				fetching: false,
				error: ''
			};
		case DOWNLOAD_SIGNEDDOCUMENT_REQUEST:
			return {
				...state,
				error: ''
			};
		case DOWNLOAD_SIGNEDDOCUMENT_SUCCESS:
			return {
				...state,
				error: ''
			};
		case DOWNLOAD_SIGNEDDOCUMENT_FAIL:
			return {
				...state,
				error: ''
			};
		case DOCUMENT_DETAIL_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case DOCUMENT_DETAIL_SUCCESS:
			return {
				...state,
				documentDetails: action.data,
				fetching: false,
				responseCallback: {
					...state.responseCallback
				},
				error: ''
			};
		case DOCUMENT_DETAIL_FAIL:
			return {
				...state,
				documentDetails: action.data,
				fetching: false,
				error: ''
			};
		case EMAILAUTHENTICATION_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case EMAILAUTHENTICATION_SUCCESS:
			return {
				...state,
				isSignerAuthenticated: action.data,
				fetching: false,
				error: ''
			};
		case EMAILAUTHENTICATION_FAIL:
			return {
				...state,
				isSignerAuthenticated: action.data,
				fetching: false,
				error: ''
			};
		case SIGNDOCUMENT_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case SIGNDOCUMENT_SUCCESS:
			return {
				...state,
				isDocumentSigned: action.data,
				fetching: false,
				error: ''
			};
		case SIGNDOCUMENT_FAIL:
			return {
				...state,
				isDocumentSigned: action.data,
				fetching: false,
				error: ''
			};
		case RESET:
			return {
				signerDetails: {},
				documentDetails: {},
				createSigner: {},
				ipAddress: {},
				isSignerAuthenticated: {},
				isDocumentSigned: {}
			};
		default:
			return state;
	}
}
