import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import language json file
import en from "./Translation/en.json";
import no from './Translation/no.json';

const defaultLanguage = 'no';
const language=localStorage.getItem('language');
if(language!=null && language!==undefined){
    localStorage.setItem('language',language);
}else{
    localStorage.setItem('language',defaultLanguage);
}

i18n
    .use(initReactI18next)                      // passes i18n down to react-i18next
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        compatibilityJSON: 'v3',                // Json version
        lng: defaultLanguage,                              // language to use
        resources: {
            en: {
                common: en                      // 'common' is our custom namespace
            },
            no:{
                common:no
            }
        },
    });

export default i18n;

