import React, { Component } from 'react';
import Loading from '../Loader/Loader';
import { connect } from 'react-redux';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import ErrorPage from '../Error/ErrorPage';
class SigningResponse extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {

    }

    static getDerivedStateFromProps(nextprops, prevState) {
        let updateState = { ...prevState };

        return updateState;
    }

    render() {
        return (
            <div>
                {!this.props.error && this.props.fetching && <Loading />}
                <div>
                    <div className="navbar navbar-inverse navbar-fixed-top">
                        <div className="container justify-content-center">
                            <div className="navbar-header">
                            </div>
                        </div>
                    </div>
                    {this.props.error ? <ErrorPage /> :
                        <div className="successmessage">
                            <h1 className="display-3">{this.props.t('esign.thankyouMsg')}</h1>
                            <FontAwesomeIcon className="successicon" icon={faCheck} />
                            <p>{this.props.t('esign.documentSignerMsg')}</p>
                        </div>
                    }
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    error: state.error.isError,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SigningResponse));
