import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { reset, getDocuments } from '../../redux/actions/SignerAction';
import Loading from '../Loader/Loader';
import ErrorPage from '../Error/ErrorPage';
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export class SignDocument extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      byteArray: {},
      base64String: "",
      numPages: null,
      pageNumber: 1,
      genericError: false,
      alreadySigned: false,
      encodedString: "",
    }
  }

  componentDidMount() {
    this.props.reset();
    const query = new URLSearchParams(window.location.search);
    const Id = query.get('id');
    if (Id === null || Id === undefined || Id === "") {
      this.setState({
        genericError: true
      })
      return null;
    }
    else {
      this.setState({
        encodedString: Id
      });
    }
    this.props.getDocuments(Id);
  }
  static getDerivedStateFromProps(nextprops, prevState) {
    let updateState = { ...prevState };
    if (nextprops.document_Details) {
      updateState = {
        ...updateState,
        base64String: nextprops.document_Details,
      };
    }
    else {
      updateState = {
        ...updateState,
        alreadySigned: true
      };
    }
    return updateState;
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  onClick = () => {
    window.location = `/authentication?id=${this.state.encodedString}`;
  };
  render() {
    const { numPages } = this.state;
    return (
      <div>
        {this.props.fetching && !this.props.error && <Loading />}
        {this.props.error || this.state.genericError ? <ErrorPage /> :
          <div>
            {!this.state.alreadySigned ?
              <div>
                {this.props.fetching && !this.props.error && <Loading />}
                <center><h2>Please click on document to sign it</h2></center>
                <div className='pdf-div'>
                  <Document
                    file={`data:application/pdf;base64,${this.state.base64String}`}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    className='clickable'
                  >
                    {Array.apply(null, Array(numPages))
                      .map((x, i) => i + 1)
                      .map((page) => {
                        return (
                          <Page
                            key={page}
                            pageNumber={page}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            onClick={this.onClick}
                          />
                        );
                      })}
                  </Document>
                </div>
              </div>
              : <div>
                <div>
                  <div style={{ marginLeft: '10px', marginTop: '5%', textAlign: "center", fontSize: '25px', color: "Red" }}>
                    <p>{this.props.t('esign.alreadySignedMsg')}</p>
                  </div>
                </div>

              </div>}
          </div>
        }
      </div>
    );
  }

}
const mapStateToProps = (state) => ({
  document_Details: state.sign.documentDetails,
  fetching: state.sign.fetching,
  error: state.error.isError,
})
const mapDispatchToProps = {
  getDocuments,
  reset
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SignDocument));