import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import reducer from '../reducers';

const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) =>
	  process.env.NODE_ENV !== 'production' ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
	devTools: process.env.NODE_ENV !== 'production',
  });

  
  export default store;
