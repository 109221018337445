import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { reset, authenticateSigner, signDocument } from '../../redux/actions/SignerAction';
import Loading from '../Loader/Loader';
import ErrorPage from '../Error/ErrorPage';

class EmailAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isValidEmail: false,
      verificationCode: '',
      isCodeVerified: false,
      isSignerAuthenticated: true,
      codeMismatchError: false,
      encodedString: "",
      genericError: false
    };
  }

  componentDidMount() {
    this.props.reset();
    const urlParams = new URLSearchParams(window.location.search);
    const decodedValue = decodeURIComponent(urlParams.get('id'));
    this.setState({
      encodedString: decodedValue
    });
  }

  static getDerivedStateFromProps(nextprops, prevState) {
    let updateState = { ...prevState };
    const authenticationDetails = nextprops.isSignerAuthenticated;
    if (Object.keys(nextprops.isSignerAuthenticated).length > 0) {
      if (authenticationDetails) {
        updateState = {
          ...updateState,
          isSignerAuthenticated: authenticationDetails.IsEmailAuthorized,
          isValidEmail: authenticationDetails.IsEmailAuthorized,
          verificationCode: authenticationDetails.Code
        };
      }
    }
    const documentSignedDetails = nextprops.isDocumentSigned;
    if (documentSignedDetails === true) {
      window.location = `/signing`;
    }
    else if (documentSignedDetails === false) {
      this.setState({ genericError: true });
    }
    return updateState;
  }
  handleChange = (event) => {
    this.props.reset();
    const emailValue = event.target.value;
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
    this.setState({
      email: emailValue,
      isValidEmail: isValidEmail,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.state.isValidEmail) {
      this.props.authenticateSigner(this.state.encodedString, this.state.email);
    }
  };

  handleVerification = (event) => {
    event.preventDefault();
    const enteredCode = event.target.code.value;
    if (this.state.verificationCode === enteredCode) {
      this.setState({ isCodeVerified: true, codeMismatchError: false });
      this.props.signDocument(this.state.encodedString);
    } else {
      this.setState({ codeMismatchError: true });
    }
  };

  render() {
    const { isValidEmail, codeMismatchError } = this.state;
    return (
      <div>
        {this.props.fetching && !this.props.error && <Loading />}
        {this.props.error || this.state.genericError ? <ErrorPage /> :
          <div className="container mt-5" style={{ background: 'whitesmoke' }}>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <h2 className="mb-4 text-center">Email Verification</h2>
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input
                      type="email"
                      id="email"
                      className={`form-control ${isValidEmail ? 'is-valid' : 'is-invalid'}`}
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="Enter your email address"
                    />
                    <div className="invalid-feedback">
                      Please enter a valid email address that matches with the signer's email address in system.
                    </div>
                  </div>
                  <div className="m-2 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary" disabled={!isValidEmail}>
                      Send Verification Code
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {this.state.verificationCode && (
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <form autoComplete="off" onSubmit={this.handleVerification}>
                    <div className="mb-3">
                      <label htmlFor="code" className="form-label">Verification Code:</label>
                      <input
                        type="text"
                        id="code"
                        className={`form-control ${codeMismatchError ? 'is-invalid' : ''}`}
                        placeholder="Enter the verification code"
                        required
                      />
                    </div>
                    {codeMismatchError && (
                      <div className='text-danger'>
                        The entered code does not match. Please try again.
                      </div>
                    )}
                    <div className="m-2 d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Verify Code
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignerAuthenticated: state.sign.isSignerAuthenticated,
  isDocumentSigned: state.sign.isDocumentSigned,
  fetching: state.sign.fetching,
  error: state.error.isError,
})

const mapDispatchToProps = {
  reset,
  authenticateSigner,
  signDocument
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(EmailAuthentication));
