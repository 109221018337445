import React from 'react';
import SignerDetails from './components/SignerDetails/SignerDetails';
import SignDocument from './components/SigningWithoutBankId/SignDocument';
import EmailAuthentication from './components/SigningWithoutBankId/EmailAuthentication';
import SigningResponse from './components/SigningWithoutBankId/SigningResponse';
import NotFound from './components/NotFound/index';
import { Routes, Route} from 'react-router-dom';
import Callback from './components/Callback/Callback';

const AppRoutes = () => (
	<Routes >
			<Route exact path="/" element={window.globalConfig.SigningWithOutBankId==="true"?<SignDocument />:<SignerDetails />}/>
			<Route path='/authentication' element={<EmailAuthentication/>}/>
			<Route path='/signing' element={<SigningResponse/>}/>
			<Route path="/callback" element={<Callback />}/>
			<Route path="*" element={<NotFound />} />
	</Routes>
);

export default AppRoutes;