import React, { Component } from 'react'
import { connect } from 'react-redux';
import { reset, getSignerDetails, createSigner, getIpAddress, getPortalLogo } from '../../redux/actions/SignerAction';
import '../../App.css';
import Loading from '../Loader/Loader';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorPage from '../Error/ErrorPage';
import { withTranslation } from 'react-i18next';
import BankIDlogoImage from '../../assets/images/BankIDlogo.png';

class SignerDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            signerName: "",
            termsAndConditions: [],
            signer: {},
            documentUrl: "",
            termsOfUse: 0,
            termsOfUseCounter: 0,
            userIpAddres: "",
            agreedTnC: false,
            isTncRead: false,
            genericError: false,
            AcceptedTimeStamp: this.convertUTCDateToLocalDate(new Date()),
            alreadySigned: false,
            languageCode: "",
            portallogo: "",
            i18props: props.i18n,
            onlyOnce: false
        }
    }
    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const Id = query.get('id');
        if (Id === null || Id === undefined || Id === "") {
            this.setState({
                genericError: true
            })
            return null;
        }
        this.props.reset();
        this.props.getIpAddress();
        this.props.getPortalLogo(Id);
        this.props.getSignerDetails(Id);
    }


    static getDerivedStateFromProps(nextprops, prevState) {
        let updateState = { ...prevState };
        const signerDetails = nextprops.signerdetails.data;
        if (Object.keys(nextprops.signerdetails).length > 0) {
            if (signerDetails !== null && signerDetails !== "" && signerDetails !== undefined) {
                updateState = {
                    ...updateState,
                    signer: signerDetails,
                    signerName: signerDetails.FirstName + ' ' + signerDetails.LastName,
                    termsAndConditions: signerDetails.TermsAndConditions
                };
            }
            else {
                updateState = {
                    ...updateState,
                    alreadySigned: true
                };
            }
        }
        if (nextprops.Settings !== null && nextprops.Settings !== undefined) {
            updateState = {
                ...updateState,
                portallogo: nextprops.Settings.ImageUrl,
                languageCode: nextprops.Settings.LanguageCode
            }
        }
        if (typeof nextprops.create_signer_url === "string") {
            setTimeout(() => {
               window.location.href = nextprops.create_signer_url;
            }, 250);
        }
        return updateState;

    }
    componentDidUpdate(prevProps) {
        if (this.props.Settings !== prevProps.Settings) {
            this.handleLanguageChange();
        }
        if (Object.keys(this.props.signerdetails).length > 0) {
            const signerDetails = this.props.signerdetails.data;
            if (this.state.onlyOnce === false && this.state.alreadySigned === false && (signerDetails.TermsAndConditions === null || signerDetails.TermsAndConditions === undefined)) {
                this.props.createSigner(this.state.signer);
                this.setState({
                    onlyOnce: true
                })
            }
        }
    }

    handleLanguageChange = () => {
        const languageCode = this.state.languageCode;
        if (languageCode === 1033) {
            this.props.i18n.changeLanguage('en');
            localStorage.setItem('language', 'en');
        }
        else if (languageCode === 1044) {
            this.props.i18n.changeLanguage('no');
            localStorage.setItem('language', 'no');
        }
    }
    updateCheckbox = ({ checked }) => {
        this.setState(
            (prev) => ({
                agreedTnC: checked,
                AcceptedTimeStamp: this.convertUTCDateToLocalDate(new Date())
            }),
        );
    };

    onClickTermsOfUse = (id) => {
        let IsClickedCount = 0;
        let stateObj = this.state;
        let tandc = [...stateObj.signer.TermsAndConditions];
        for (let i = 0; i < tandc.length; i++) {
            if (tandc[i].Id === id) {
                tandc[i].IsClicked = true;
                tandc[i].ClickedTimeStamp = this.convertUTCDateToLocalDate(new Date());

            }
        }
        stateObj.signer.TermsAndConditions = tandc;
        this.setState(stateObj);
        IsClickedCount = this.state.signer.TermsAndConditions.filter((obj) => obj.IsClicked === true).length;
        if (IsClickedCount === this.state.signer.TermsAndConditions.length) {
            this.setState({
                isTncRead: true
            })
        }

    }

    getBrowserInfo = () => {
        let agent = navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf("edge") > -1: return "MS Edge";
            case agent.indexOf("edg/") > -1: return "Edge ( chromium based)";
            case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
            case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
            case agent.indexOf("trident") > -1: return "MS IE";
            case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
            case agent.indexOf("safari") > -1: return "Safari";
            default: return "other";
        }
    }

    getSystemInfo = () => {
        var OSName = "Unknown";
        if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1) OSName = "Windows 10";
        if (window.navigator.userAgent.indexOf("Windows NT 6.3") !== -1) OSName = "Windows 8.1";
        if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1) OSName = "Windows 8";
        if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1) OSName = "Windows 7";
        if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1) OSName = "Windows Vista";
        if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1) OSName = "Windows XP";
        if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1) OSName = "Windows 2000";
        if (window.navigator.userAgent.indexOf("Mac") !== -1) OSName = "Mac/iOS";
        if (window.navigator.userAgent.indexOf("X11") !== -1) OSName = "UNIX";
        if (window.navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";
        return OSName;
    }

    convertUTCDateToLocalDate = (date) => {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    onAcceptTermsAndConditions = () => {
        let signerDetail = {
            SignerId: "",
            Email: "",
            PhoneNumber: "",
            FirstName: "",
            LastName: "",
            TaskId: "",
            TermsAndConditions: "",
            OrgId: "",
            TaskName: ""
        }
        let termsAndConditions = [];
        if (this.state.agreedTnC === true) {
            var information = "";
            var browserName = this.getBrowserInfo();
            var ipAddress = this.props.ipaddress;
            var systemName = this.getSystemInfo();
            information = `System: ${systemName}\nBrowser: ${browserName}\nIP: ${ipAddress}`;
            for (let i = 0; i < this.state.signer.TermsAndConditions.length; i++) {
                let postData = {
                    Id: "",
                    Name: "",
                    URL: "",
                    Type: "",
                    TypeId: "",
                    ClickedSessionAndDeviceInfo: "",
                    AcceptedSessionAndDeviceInfo: "",
                    IsClicked: false,
                    IsAccepted: false,
                    ClickedTimeStamp: "",
                    AcceptedTimeStamp: "",
                    Notes: []
                };
                postData.Id = this.state.signer.TermsAndConditions[i].Id;
                postData.Name = this.state.signer.TermsAndConditions[i].Name;
                postData.URL = this.state.signer.TermsAndConditions[i].URL;
                postData.Type = this.state.signer.TermsAndConditions[i].Type;
                postData.TypeId = this.state.signer.TermsAndConditions[i].TypeId;
                postData.ClickedSessionAndDeviceInfo = information;
                postData.AcceptedSessionAndDeviceInfo = information;
                postData.IsClicked = this.state.signer.TermsAndConditions[i].IsClicked;
                postData.IsAccepted = true;
                postData.ClickedTimeStamp = this.state.signer.TermsAndConditions[i].ClickedTimeStamp;
                postData.AcceptedTimeStamp = this.state.AcceptedTimeStamp;
                postData.Notes = this.state.signer.TermsAndConditions[i].Notes
                termsAndConditions.push(postData);
            }
            signerDetail.SignerId = this.state.signer.SignerId;
            signerDetail.Email = this.state.signer.Email;
            signerDetail.PhoneNumber = this.state.signer.PhoneNumber;
            signerDetail.FirstName = this.state.signer.FirstName;
            signerDetail.TaskId = this.state.signer.TaskId;
            signerDetail.TermsAndConditions = termsAndConditions;
            signerDetail.OrgId = this.state.signer.OrgId;
            signerDetail.TaskName = this.state.signer.TaskName;
            this.props.createSigner(signerDetail);
        }
    }

    render() {
        return (
            <div>
                {this.props.fetching && !this.props.error && <Loading />}
                <div className="navbar navbar-inverse navbar-fixed-top">
                    <div className="container justify-content-center">
                        <div className="navbar-header">
                            <img src={this.state.portallogo} height="50" className="navbar-brand" alt="E-Sign Portal" />
                        </div>
                    </div>
                </div>
                {this.props.error || this.state.genericError ? <ErrorPage /> :
                    <div>
                        {!this.state.alreadySigned ?
                            <div className="col-md-6 col-md-push-3 col-sm-12 inner-content-box">
                                {window.globalConfig.UseTermsAndConditionPage==="true"?
                                <div>
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="well well-sm">
                                            <h4>{this.state.signerName}</h4>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>{this.props.t('esign.termsOfUseMsg')}</h4>
                                        {this.state.termsAndConditions != null ?
                                            this.state.termsAndConditions.map((key, i) => (
                                                key.TypeId === "914600001" ?
                                                    <div key={"terms" + i} className="d-flex align-items-center">
                                                        <input type="checkbox" id={"readtermsurl" + i} className="form-check-input termsCheckedbox" readOnly checked={key.IsClicked} />
                                                        <FontAwesomeIcon className="icon" icon={faExternalLinkAlt} /> <a href={key.URL} target="_blank" rel="noreferrer" onClick={() => { this.onClickTermsOfUse(key.Id) }}>{key.URL}</a>
                                                    </div> : key.TypeId === "914600000" ?
                                                        key.Notes.map((notekey, j) => (
                                                            <div key={"downloadFile" + j} className="d-flex align-items-center">
                                                                <input type="checkbox" id={"readtermsdocument" + i} className="form-check-input termsCheckedbox" readOnly checked={key.IsClicked} />
                                                                <FontAwesomeIcon className="icon" icon={faDownload} /><a notekey={j} target="_blank" rel="noreferrer" onClick={() => { this.onClickTermsOfUse(key.Id) }} href={"data:" + notekey.FileType + ";base64," + notekey.Base64String} download={notekey.FileName}>{notekey.FileName}</a><br></br>
                                                            </div>
                                                        )) : ""
                                            ))
                                            : ""
                                        }
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-check">
                                            <input type="checkbox" id="agree" className="form-check-input"
                                                onChange={(e) => this.updateCheckbox(e.target)} disabled={!this.state.isTncRead} />
                                            <label className="form-check-label" htmlFor="agree">{this.props.t('esign.agreementMsg')}</label>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" id="btnSubmit" name="type" className="btn btn-primary buttons" onClick={this.onAcceptTermsAndConditions}
                                    disabled={!this.state.agreedTnC}>{this.props.t('esign.continueMsg')}</button>
                                    </div>:
                                <div className="row">
                                    <div>
                                        <div className="centerTxt">
                                            <h4>{this.props.t('esign.redirectMsg')}</h4>
                                        </div>
                                        <br></br>
                                        <div className="centerImg">
                                        <img src={BankIDlogoImage}  alt="BankID" height="40px"  />
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                            : <div>
                                <div>
                                    <div style={{ marginLeft: '10px', marginTop: '5%', textAlign: "center", fontSize: '25px', color: "Red" }}>
                                        <p>{this.props.t('esign.alreadySignedMsg')}</p>
                                    </div>
                                </div>

                            </div>}
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    create_signer_url: state.sign.createSignerUrl,
    signerdetails: state.sign.signerDetails,
    ipaddress: state.sign.ipAddress,
    fetching: state.sign.fetching,
    responseData: state.sign.responseCallback,
    error: state.error.isError,
    Settings: state.sign.settings
})

const mapDispatchToProps = {
    getSignerDetails,
    createSigner,
    getIpAddress,
    getPortalLogo,
    reset
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(SignerDetails));